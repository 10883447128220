<div class="records-container">
    <div class="record">
        <p class="record-title text-center">Events Happend Today: {{formattedDate}}</p>
    </div>
    <div class="record" *ngFor="let e of events">
      <p class="record-title">{{e.year}}</p>
      <p class="record-description">{{e.description}}</p>
    </div>

    <!-- Add more records as needed -->
  </div>