<div class="col-md-12">
    <div class="card card-container">
        <div>
            <H1>Make Payment & Proceed</H1>
        </div>
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <div class="form-group">
                <div class="alert alert-danger" role="alert" *ngIf="error">Payment failed: {{ error }}</div>
                <div class="alert alert-success" role="alert" *ngIf="paymentId">Payment Success. Payment ID: {{ paymentId }}</div>
            </div>
            <div class="form-group">
                <label for="name">Name</label> <input type="text" class="form-control" name="name" [(ngModel)]="form.name"  [(ngModel)]="user.name" disabled minlength="3" maxlength="20" #name="ngModel" />
                <div class="alert-danger" *ngIf="f.submitted && name.invalid">
                    <div *ngIf="name.errors.required">Name is required</div>
                    <div *ngIf="name.errors.minlength">Name must be at least 3 characters</div>
                    <div *ngIf="name.errors.maxlength">Name must be at most 20 characters</div>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label> <input type="text" class="form-control" name="email" [(ngModel)]="form.email" [(ngModel)]="user.email" disabled required #email="ngModel" />
                <div class="alert alert-danger" role="alert" *ngIf="f.submitted && email.invalid">Email is required!</div>
            </div>
            <div class="form-group">
                <label for="phone">Phone</label> <input type="number" class="form-control" name="phone" [(ngModel)]="form.phone" [(ngModel)]="user.phoneno"  minlength="10" maxlength="10"
                    #phone="ngModel" />
                <div class="alert alert-danger" role="alert" *ngIf="f.submitted && phone.invalid">
                    <div *ngIf="phone.errors.required">Phone is required</div>
                    <div *ngIf="phone.errors.minlength || phone.errors.maxlength">Phone must be 10 digits</div>
                </div>
            </div>
            <div class="form-group">
                <label for="amount">Amount</label> <input type="number" class="form-control" name="amount" [(ngModel)]="form.amount" [(ngModel)]="fee" disabled  #amount="ngModel" />
                <div class="alert alert-danger" role="alert" *ngIf="f.submitted && amount.invalid">
                    <div *ngIf="amount.errors.required">Amount is required</div>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="!f.valid" class="btn btn-primary btn-block">Pay</button>
            </div>
        </form>
    </div>
</div>