<div class="bootstrap-wrapper">

    <div class="container">

        <div class="row">
            <div class="col-md-8 offset-md-2">
                <mat-card>
                    <form (ngSubmit)="resetPassword()">
                        <h1 class="text-center">Reset Password</h1>
                        <h5 class="text-center">You will receive OTP on your mail</h5>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Phone</mat-label>
                                    <input required name="phone" [(ngModel)]="phone" type="tel" minlength="10"  maxlength="10" matInput placeholder="Registered phone Number (10 digits)!">
                                 </mat-form-field>
                            </div>
                            <div class="container text-center">
                                <button  mat-raised-button color="primary" type="submit">Send OTP</button>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>