<div class="bootstrap-wrapper">


    <div class="container-fluid">

        <div class="row mt20">

            <div class="col-md-8">
                <mat-card>
                    <mat-card-title>User Progress</mat-card-title>
                    <p class="text-center">HTML</p>
                    <div class="container">
                        <div class="skills html">90%</div>
                    </div>

                    <p class="text-center">CSS</p>
                    <div class="container">
                        <div class="skills css">80%</div>
                    </div>

                    <p class="text-center">JavaScript</p>
                    <div class="container">
                        <div class="skills js">65%</div>
                    </div>

                    <p class="text-center">PHP</p>
                    <div class="container">
                        <div class="skills php">100%</div>
                    </div>

                </mat-card>
            </div>



            <div class="col-md-4">
                <mat-card>

                </mat-card>
            </div>

        </div>
        <!-- second row  -->

        <div class="row mt20">

            <div class="col-md-12">
                <mat-card>
                    <mat-card-title>New User</mat-card-title>
                    <p class="text-center">HTML</p>
                    <div class="container">
                        <div class="skills html">90%</div>
                    </div>

                    <p class="text-center">CSS</p>
                    <div class="container">
                        <div class="skills css">80%</div>
                    </div>

                    <p class="text-center">JavaScript</p>
                    <div class="container">
                        <div class="skills js">65%</div>
                    </div>

                    <p class="text-center">PHP</p>
                    <div class="container">
                        <div class="skills php">100%</div>
                    </div>

                </mat-card>
            </div>

            <!-- second row  -->


        </div>

    </div>
</div>