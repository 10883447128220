<div class="bootstrap-wrapper">
    <div class="row mt20">
        <div class="col-md-12">
            <div class="text-center">
                <button type="button" class="btn btn-primary px-5" (click)="createBlog()">Post Your Blog</button>
              </div>
        </div>
    </div>
    <div class="row mt20">
        <div *ngIf="postRecords.posts"></div>
        <div class="col-md-9" *ngFor="let q of postRecords.posts">
            <mat-card class="mb20 custom-card mat-card">
                <mat-card-content>
                    <a [routerLink]="'/posts/publishedpost/' + q.title">
                        <div class="card-header capitalize">
                            <h2>{{formatTitle(q.title)}}</h2>
                        </div>
                    </a>
                    <div class="card-details content">
                        <div [innerHTML]="extract240Characters(q.content, q.title)"></div>
                        <span *ngIf="q.author.name">Author: <b>{{q.author.name}}</b></span>
                        <p>Date: {{formatDate(q.date)}}</p>   
                    </div>
                    </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>