<div class="bootstrap-wrapper">

    <div class="container">

        <div class="row">
            <div class="col-md-6 offset-md-3">
                <mat-card>
                    <h1 class="text-center">Activate your account</h1>
                    <h5 class="text-center">Check your mail for OTP</h5>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input required [(ngModel)]="verifyData.email" name="email" type="text" readonly matInput placeholder="Enter Here!">
                            </mat-form-field>
                        </div>
                        <div class="col-md-12">
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>OTP</mat-label>
                                <input required [(ngModel)]="verifyData.otp" name="otp" type="text" matInput placeholder="Enter OTP Here!">
                            </mat-form-field>
                        </div>
                        <div class="container text-center">
                            <button (click)="verifyUser()" mat-raised-button color="primary">Verify</button>
                            <button (click)="resendOTP()" class="ml20" mat-raised-button color="accent">Resend OTP</button>
                        </div>
  
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>