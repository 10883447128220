import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-govtjobs',
  templateUrl: './govtjobs.component.html',
  styleUrls: ['./govtjobs.component.css']
})
export class GovtjobsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
