<div class="bootstrap-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-body">
              <h2 class="card-title text-center">Post a Blog</h2>
              <div class="form-group text-center">
                <!-- <label for="blogTitle" class="text-primary font-weight-bold">Title:</label> -->
                <input type="text" class="form-control bg-light border-primary rounded-pill" id="blogTitle" [(ngModel)]="blog.title" placeholder="Enter a title">
              </div>
              <div class="form-group">
                <!-- <label for="blogContent" class="text-primary font-weight-bold">Content:</label> -->
                <ckeditor [(ngModel)]="blog.content" [editor]="Editor"></ckeditor>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary px-5" (click)="postBlog()">Post</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  