<div class="content">
    <h2 class="centre">Welcome to <b><a href="/">ExamDedo</a></b> – Empowering Students through Performance Evaluation</h2>

    <p>
        At <a href="/">ExamDedo</a>, we're dedicated to revolutionizing the way students gauge their academic progress and enhance their learning experiences.
    We understand the importance of continuous evaluation and its impact on students' educational journeys. 
    Hence, we have crafted a user-friendly and accessible platform where students can evaluate their performance conveniently and effectively.
    </p>

    <h2 class="centre">Our mission</h2>
    <p>
        Our mission is to provide students with a comprehensive, reliable, and affordable platform for assessing their knowledge, identifying strengths and weaknesses, and ultimately improving their academic performance.
        We aim to empower students by offering them an opportunity to measure their understanding of various subjects and topics through a hassle-free online exam system.
    </p>

    <h2 class="centre">What Sets Us Apart</h2>

        <p><b>Diverse Exam Categories:</b> We offer a wide array of exam categories covering different subjects and topics, ensuring students from various educational backgrounds can find suitable assessments.</p>

        <p><b>Quality Assessments:</b> Our exams are meticulously designed and regularly updated by subject matter experts to ensure accuracy and relevance to current educational standards. </p>

        <p><b>Performance Analytics:</b> Through detailed performance reports and analytics, we provide students with insights into their strengths and areas needing improvement, allowing them to tailor their study strategies accordingly.</p>

        <p><b>Affordability:</b> We believe in making quality education accessible. Hence, our nominal fees ensure that students from all walks of life can benefit from our assessment services without financial constraints.</p>

        <h2 class="centre">Our Commitment</h2>
        <p>
            At <a href="/">ExamDedo.com</a>, we are committed to providing an intuitive, secure, and enriching experience for our users. We prioritize user satisfaction and continuously strive to enhance our platform's functionality, user interface, and content to meet the evolving needs of students.
        </p>

    <h2 class="centre">Get Started with <a href="/">ExamDedo.com</a></h2>
    <p>
        Join us on this journey of self-improvement and academic excellence! <a [routerLink]="'/signup'">Sign Up</a> today to explore our diverse range of exams and take the first step towards evaluating your performance and achieving your educational goals.

        Thank you for choosing [Your Website Name] for your assessment needs. We look forward to supporting you on your learning path!

        Feel free to customize this content according to your specific branding, values, and the unique features your online exam portal offers to students.

    </p>
</div>