<div class="bootstrap-wrapper">
    <div class="container">
        <div class="row">
            <div class="question-form-container col-12 offset-md-3 col-md-6">
                <form class="question-form" (ngSubmit)="formSubmit()">
                  <h2 class="form-title">Question of the Day</h2>
                  <div class="form-group">
                    <label for="question">Question:</label>
                    <p><span [innerHTML]="question"></span></p>
                  </div>
                  <div class="form-group">
                    <div class="options" *ngFor="let option of options; let i=index">
                        <div>
                            <label>
                              <input class="option" type="radio" [(ngModel)]="givenAnswer" [value]="option" name="options"> <span [innerHTML]="option"></span>
                            </label>
                          </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="email">Email*:</label>
                    <input placeholder="Enter your email" [(ngModel)]="email" type="email" id="email" name="email" class="form-control" required>
                  </div>
                  <button type="submit"  class="btn btn-primary">Submit</button>
                </form>
              </div>
                            
        </div>
    </div>
</div>

  