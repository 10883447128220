import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService, private userService: UserService) { }

  user = null;
  verifyData = {
    userid: '',
    otp: '',
    email: ''
  }

  resendOTPData = {
    userid: '',
    email: ''
  }

  ngOnInit(): void {
    this.user = this.loginService.getUser();
    this.verifyData.email = this.user.email;
    this.verifyData.userid = this.user.id;
  }

  verifyUser() {
    this.userService.verifyUser(this.verifyData).subscribe(
      (response:any)=>{
        this.loginService.setUser(response.data);
        this.router.navigate(['/']);
      },
      (error) => {
        alert("Error");
      }
    );
  }

  resendOTP(){
    this.resendOTPData.userid = this.user.id;
    this.resendOTPData.email = this.user.email;

    this.userService.resendOTP(this.resendOTPData).subscribe(
      (response:any)=>{
        Swal.fire({
          title:'OTP sent to your mail!, activate your account',
           showCancelButton:false,
           confirmButtonText: 'Ok',
           icon: 'info',
        })
      },
      (error) => {
        alert("Error in sending OTP");
      }
    );
  }

}
