<div class="bootstrap-wrapper">
        <div class="row">
            <div class="col-md-12">
                <mat-card class="mt20">
                    <mat-card-header>
                        <!-- Heading -->
                        <mat-card-title>
                            <!-- Title -->
                            Read the below instructions before taking the mock test of <b>

                            <span *ngIf="quizessData.data.gradeId >= 100">{{quizessData.data.name}}</span>
                            <span *ngIf="quizessData.data.gradeId < 100">class {{quizessData.data.gradeId}}th {{quizessData.data.name}}</span>
                            </b>
                        </mat-card-title>
                        <mat-card-subtitle>
                            <!-- Sub Title -->
                            One Step more to go
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="ml20">
                        <!-- Content -->
                        <mat-divider></mat-divider>

                        <br>

                        <h1>Important Instructions</h1>
                        <ul>
                            <li>Total time to solve Quiz is : <b>{{quizessData.data.duration}} <span>: Minutes</span></b></li>
                            <li>No Of Questions : <b>{{quizessData.data.numberOfQuestions}}</b> </li>
                            <li>Price of the Quiz : <b>{{quizessData.data.price}}</b> </li>
                            <li><b>Make sure payment has been done</b></li>

                        </ul>

                        <mat-divider></mat-divider>
                        <br/>
                        <h1>Attempting Quiz</h1>
                        <ul>
                            <li>Click <b>Start Quiz</b> to start quiz</li>
                            <li>Please Do not refresh page otherwise question will be lost and new questions will suffles</li>
                            <li>Don't switch tab </li>
                            <li>Don't minimize current window </li>


                        </ul>

                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <!-- Action -->
                        <button (click)="startQuiz()" mat-raised-button color="accent">Make Payment & Start Test</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
</div>