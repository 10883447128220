import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-reset-password-confirmation',
  templateUrl: './reset-password-confirmation.component.html',
  styleUrls: ['./reset-password-confirmation.component.css']
})
export class ResetPasswordConfirmationComponent implements OnInit {

  resetPassConfirmObj = {  }
  otp : string = '';
  password: string = '';
  confirmPassword: string = '';

  constructor(private route: Router, private userService: UserService, private snack: MatSnackBar) { }

  ngOnInit(): void {
  }

  resetPasswordConfirmation(){
    if(this.isDataValid()) {
      this.resetPassConfirmObj["phone"] = localStorage.getItem("phone");
      this.resetPassConfirmObj["otp"] = this.otp.trim();
      this.resetPassConfirmObj["password"] = this.password;
      this.resetPassConfirmObj["confirmPassword"] = this.confirmPassword;
      this.userService.resetPasswordConfirm(this.resetPassConfirmObj).subscribe(
        (response: any) =>{
          localStorage.removeItem("phone");
          Swal.fire("Success" , "Password has been successfully reset, Now Login to continue!" , "success");
          this.route.navigate(['/login']);
        },(error)=> {

        });

    }
  }

  isDataValid(){
    if(this.password == undefined || this.password == null || this.password== '') {
      this.snack.open("password is required!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return false;
    }
    if(this.confirmPassword == undefined || this.confirmPassword == null || this.confirmPassword== '') {
      this.snack.open("confirm password is required!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return false;
    }
    if(this.otp == undefined || this.otp == null || this.otp.trim()== '') {
      this.snack.open("OTP is required!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return false;
    }
    if(this.password != this.confirmPassword) {
      this.snack.open("password and confirm pssword must be same!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return false;
    }

    return true;
  }

}
