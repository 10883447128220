import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import baseUrl from './helper';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyServiceService {

  constructor(private http: HttpClient) { }


  fetchData(day:number, month:number) {
    return this.http.get(`${baseUrl}/thirdparty/v1/onthisday/${day}/${month}`);
  }
}
