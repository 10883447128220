<div class="bootstrap-wrapper">
      <div class="row justify-content-center">
        <div class="col-md-9">
            <div class="card-body">
                <div class="header-style">
                    <h1 class="title">{{title}}</h1>
                    <p>Author: <b> {{ author.name }}</b></p>
                    <p>Date: <b>{{ date }}</b></p>
                </div>
              <div class="content" [innerHTML]="content" ></div>
            </div>
        </div>
        <div class="col-md-3"></div>
      </div>
  </div>
  
