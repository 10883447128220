import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user = null;
  status = '';

  constructor(private loginService:LoginService) { }

  ngOnInit(): void {
    //data fetch from local Storage
     this.user = this.loginService.getUser();
  }

}
