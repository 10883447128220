import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit {
  postRecords: any;

  constructor(private route: Router, private loginService: LoginService, private sanitizer: DomSanitizer, private postService: PostService) {}

  ngOnInit(): void {
    this.getPosts(0, 50, "date", "desc");
  }

  getPosts(pageNo:number, pageSize: number, sortBy: string, sortDir: string) {
    this.postService.getAllPost(pageNo, pageSize, sortBy, sortDir).subscribe(
      (response)=> {
        this.postRecords = response["data"];
      },
      (error)=> {
        alert();
      }
    );
  }

  formatTitle(title) {
    var new_title = title.split("-").join(" ");
    if(new_title.trim() == title)
      return title;
    title = new_title.substring(0, new_title.lastIndexOf(" "));
    return title;
  }

  formatDate(timestamp) {
    // Create a new Date object using the provided timestamp
    const date = new Date(timestamp);

    // Get the day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    // Array of month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Concatenate the formatted date parts
    const formattedDate = `${day}-${monthNames[date.getMonth()]}-${year}`;

    return formattedDate;
  }

  extract240Characters(postContent: SafeHtml, title: string): string {
    // Sanitize and convert to string
    const sanitizedString = this.sanitizer.sanitize(
      SecurityContext.HTML,
      String(postContent)
    ) || '';

    // Extract only the first 300 characters
    const extractedCharacters = sanitizedString.substring(0, 300);

    // If the string is longer than 300 characters, append '...'
    if (sanitizedString.length > 300) {
      return extractedCharacters + '...'  + "<a href='/posts/publishedpost/" + title + "'> Read More</a>";
    }
    // <a [routerLink]="'/posts/publishedpost/' + q.title"> click</a>
    return extractedCharacters + "<a href='/posts/publishedpost/" + title + "'> Read More</a>";
  }

  createBlog(){
    var isCorrectUser = true;
    if(!this.loginService.isLoggedIn()) {
      isCorrectUser = false;
      Swal.fire({
        title: 'Please login to create a blog!',
        showCancelButton: true,
        confirmButtonText:'OK',
        icon: 'warning',
      }).then(e => {
        if(e.isConfirmed) {
          this.route.navigate(['/login']);
        }
      })
    }
    var user = this.loginService.getUser();
    if(user.status != 'a'){
      isCorrectUser = false;
      Swal.fire({
        title: 'Please activate your account to create a blog!',
        showCancelButton: true,
        confirmButtonText:'OK',
        icon: 'warning',
      }).then(e => {
        if(e.isConfirmed) {
          this.route.navigate(['/account-activate']);
        }
      })
    }

    if(isCorrectUser){
      this.route.navigate(['/posts/createpost']);
    }

  }

}
