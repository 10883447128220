import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-published-post',
  templateUrl: './published-post.component.html',
  styleUrls: ['./published-post.component.css']
})
export class PublishedPostComponent implements OnInit {

  title: string;
  content: SafeHtml;
  date: any;
  author: any
  constructor(private sanitizer: DomSanitizer,private route: ActivatedRoute, private postService: PostService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.title = params['title'];
    });

    this.postService.getPostByTitle(this.title).subscribe(
      (resp)=>{
        var data = resp["data"];
        this.date = this.formatDate(data.date);
        this.author = data.author;
        this.content = this.sanitizer.bypassSecurityTrustHtml(data.content);
        this.formatTitle(this.title);
      },(error)=>{
        alert("error")
      })



  }

  formatDate(timestamp) {
    // Create a new Date object using the provided timestamp
    const date = new Date(timestamp);

    // Get the day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    // Array of month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    // Concatenate the formatted date parts
    const formattedDate = `${day}-${monthNames[date.getMonth()]}-${year}`;

    return formattedDate;
}

  formatTitle(title) {
    var new_title = this.title.split("-").join(" ");
    this.title = new_title.substring(0, new_title.lastIndexOf(" "));
  }

}
