import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-report-generate',
  templateUrl: './report-generate.component.html',
  styleUrls: ['./report-generate.component.css']
})
export class ReportGenerateComponent implements OnInit {

  examReport = {};
  questions = [];
  examId;
  total_question = 0;
  correct_answers = 0
  incorrect_answer = 0;
  not_attempted = 0;

  constructor(private _route : ActivatedRoute, private _quizService : QuizService) { }

  ngOnInit(): void {
    this.examId = this._route.snapshot.params.examId;
    console.log("this.examId", this.examId);
    this.loadExamReport();
  }

  loadExamReport(){
    var isCompetitive = JSON.parse(localStorage.getItem("subject")).competitive;
    if(isCompetitive) {
      this._quizService.getCompetitiveExamReport(this.examId).subscribe(
        (response: any)=> {
          this.examReport = response.data;
          this.questions = response.data.finalReport;
          this.generateReport(response.data.finalReport)
        }, 
        (error)=>{
  
        }
      );
    } else {
      this._quizService.getExamReport(this.examId).subscribe(
        (response: any)=> {
          this.examReport = response.data;
          this.questions = response.data.finalReport;
          this.generateReport(response.data.finalReport)
        }, 
        (error)=>{
  
        }
      );
    }
    
  }

  generateReport(finalReport: []) {
    if(finalReport != undefined) {
      this.total_question = finalReport.length;
      this.correct_answers = finalReport.filter((data) => { 
        return data["answerCorrect"] == true 
      }).length;
  
    }
  }

}