<div class="bootstrap-wrapper">
    <div class="container-fluid d-flex flex-column vh-100">
        <div class="container mt-4">
            <div class="row">
              <div class="col-md-12 toppane">{{subject}}
                <h4 style="float: right; color: white;">Time: {{getFormatedTime() }} </h4></div>
            </div>
            <div class="row">
              <!-- First Section: Individual Question -->
              <div class="col-md-9 leftpane">
                <h3>Question {{ cqNumber + 1 }}</h3>
                <p><span [innerHTML]="cq.question"></span></p>

                <h3>Options</h3>
                <div>
                  <label>
                    <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op1" name="options"> <span [innerHTML]="cq.op1"></span>
                  </label>
                </div>
                <div>
                    <label>
                      <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op2" name="options"><span [innerHTML]="cq.op2"></span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op3" name="options"><span [innerHTML]="cq.op3"></span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input type="radio" [(ngModel)]="cq.givenAnswer" [value]="cq.op4" name="options"><span [innerHTML]="cq.op4"></span>
                    </label>
                  </div>
          
                <!-- Navigation Buttons -->
                <div class="mt-3">
                  <button class="btn btn-secondary" (click)="prevQuestion()" [disabled]="cqNumber === 0">Prev</button>
                  <button class="btn btn-primary" (click)="nextQuestion()" [disabled]="cqNumber === questions.length - 1">Save & Next</button>
                  <button class="btn btn-success" (click)="submitQuiz()">Submit</button>
                </div>
              </div>
          
              <!-- Third Section: Question Buttons -->
              <div class="col-md-3 rightpane">
                <h3>All Questions</h3>
                <div class="row">
                    <div *ngFor="let question of questions; let i = index">
                        <button
                        class="btn pannel_buttons"
                        [ngClass]="{'visited': isVisited(i), 'submitted': isSubmitted(i)}"
                        (click)="goToQuestion(i)"
                      >
                        {{ i + 1 }}
                      </button>
                    </div>
                </div>
                <!-- Start a new row after every 10 questions -->
      
              </div>
            </div>
          </div>
    </div>
</div>
