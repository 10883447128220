<mat-card>

    <mat-action-list>

        <div mat-subheader>Menu</div>
        <button mat-list-item routerLink="/admin"><mat-icon mat-list-icon>grid_view</mat-icon>DashBoard</button>

        <button mat-list-item routerLink="/admin/profile">
            <mat-icon mat-list-icon>account_circle</mat-icon>
            Profile
        </button>
        <button mat-list-item routerLink="/admin/categories">
            <mat-icon mat-list-icon>category</mat-icon>
            Categories
        </button>
        <button mat-list-item routerLink="/admin/add-category">
            <mat-icon mat-list-icon>add_shopping_cart</mat-icon>
            Add Categories
        </button>
        <button mat-list-item routerLink="/admin/quizzes">
            <mat-icon mat-list-icon>quiz</mat-icon>
            Quizzes
        </button>
        <button mat-list-item routerLink="/admin/add-quiz">
            <mat-icon mat-list-icon>add</mat-icon>
            Add Quiz
        </button>
        <button mat-list-item routerLink="/admin/error404">
            <mat-icon mat-list-icon>logout</mat-icon>
            Logout
        </button>

        <button mat-list-item routerLink="/admin/profile">
            <mat-icon mat-list-icon>logout</mat-icon>
            Demo
        </button>
        <button mat-list-item routerLink="/admin/profile">
            <mat-icon mat-list-icon>logout</mat-icon>
            Demo
        </button>



    </mat-action-list>

</mat-card>