import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private route: Router, private userService: UserService, private loginService: LoginService, private snack: MatSnackBar) { }

  // User Binding Object
  public user = {
    name: '',
    password: '',
    confirmPassword: '',
    email: '',
    phoneno: '',
    preparingFor: '',
    grade: '',
    referralCode: ''

  };

  loginData = {
    username: '',
    password: '',
  };

  ngOnInit(): void {
  }

  validateEmail(email){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  // Form Submit
  formSubmit() {
    console.log(this.user)

    // Validations

    if (this.user.name == '' || this.user.name == null) {
      // alert("Username Required!!")
      this.snack.open("Username is required!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }

    if (this.user.email == '' || this.user.email == null) {
      // alert("Email Required!!")
      this.snack.open("Email is required!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }
    if(!this.validateEmail(this.user.email)){
      this.snack.open("Email is invalid!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }

    if (this.user.password == '' || this.user.password == null) {
      this.snack.open("password is required!!", 'ok', {
        duration: 3000,
      });
      return;
    }

    if (this.user.confirmPassword == '' || this.user.confirmPassword == null) {
      this.snack.open("confirmPassword is required!!", 'ok', {
        duration: 3000,
      });
      return;
    }

    if (this.user.confirmPassword != this.user.password) {
      this.snack.open("confirmPassword and Password must be same!!", 'ok', {
        duration: 3000,
      });
      return;
    }

      if (this.user.phoneno == '' || this.user.phoneno == null) {
        this.snack.open("phone number is required!!", 'ok', {
          duration: 3000,
        });
        return;
      }

      // AddUser Method Calling From UserServices
      this.userService.addUser(this.user).subscribe(
        (data:any) => {
          //Success
          console.log(data)
          
          Swal.fire({
            title:'OTP sent to your mail!, activate your account',
             showCancelButton:false,
             confirmButtonText: 'Ok',
             icon: 'info',
          }).then((result)=>{
            if (result.isConfirmed){
              //location.href="/login";
              // Request to server to generate JWT Token
              this.loginData.username = this.user.phoneno;
              this.loginData.password = this.user.password;
              this.loginService.generateToken(this.loginData).subscribe(
                (data: any) => {
                  // Success
                  console.log(data);
                  // alert("Succeessfully Token genrated!!!");

                  // After Successfully generated token then login from here!!!
                  this.loginService.loginUser(data.token);

                  // getting current user
                  this.loginService.getCurrentUser(this.loginData.username).subscribe(
                    (response: any) => {

                      // save user data into localStorage
                      this.loginService.setUser(response.data);
                      console.log(response);
                      this.route.navigate(['/account-activate']);
                      this.loginService.loginStatusSubject.next(true);
                    },
                  );

                },
                (error) => {
                  // Error Occured
                  console.log('Oops...', 'Seems like Something went wrong!!!', error);
                  
                }
              );
            }
          });
          
        },
        (error) => {
          //Error
          console.log(error)
          // alert("Something went to wrong!!!")
          // this.snack.open("Something went wrong!!!",'ok',{
          //   duration: 3000,
          // });
         Swal.fire(error.error.errors[0], "","error")
           //this.snack.open(error.error.errors[0],"ok");
        }
      );

    }

  }
