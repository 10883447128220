<div class="bootstrap-wrapper">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
                <h3 style="text-align: center;">Quiz Report</h3>
                <h5 style="text-align: center;">Total Questions : {{total_question}}  Correct Answers : {{correct_answers}}</h5>
                
                
                <!-- <h1>Marks Obtained: {{marksGot}}</h1>
                
                <h1>Question Attemped: {{attempted}}</h1> -->
                <div class="scrollable-container">
                    <div *ngFor="let q of questions; let i = index">
                        <mat-card class="attractive-card">
                            <mat-card-header>
                              <mat-card-title class="smallFonts">{{i+1}}. <span [innerHTML]="q.question"></span></mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                                <ul class="no-bullet">
                                    <li [ngStyle]="{ 'color': q.answerCorrect ? 'green' : 'red' }">Your Answer:  <span [innerHTML]="q.givenAns"></span></li>
                                    <li>Correct Answer :<span [innerHTML]="q.correctAns"></span> </li>
                                </ul>
                            </mat-card-content>
                            <!-- <mat-card-actions> // Need to add challenge button
                              <button mat-button>Share</button>
                              <button mat-button>Learn More</button>
                            </mat-card-actions> -->
                        </mat-card>
                          
                    </div>
                </div>                
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>