import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor() { }
  setMetaData(data: { title: string, description: string, thumbnail: string }): void {
    document.title = data.title;
    const metaTags = document.getElementsByTagName('meta');
    for (let i = 0; i < metaTags.length; i++) {
      if (metaTags[i].getAttribute('property') === 'og:title') {
        metaTags[i].setAttribute('content', data.title);
      }
      if (metaTags[i].getAttribute('property') === 'og:description') {
        metaTags[i].setAttribute('content', data.description);
      }
      if (metaTags[i].getAttribute('property') === 'og:image') {
        metaTags[i].setAttribute('content', data.thumbnail);
      }
    }
  }

}
