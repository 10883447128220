import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) {
    this.title.setTitle("ExamDedo | About Us")
    this.meta.updateTag({ property: 'og:title', content: 'About Us' });
    this.meta.updateTag({ property: 'og:description', content: 'Description of your subpage' });
    this.meta.updateTag({ property: 'og:image', content: 'URL to an image for your subpage' });
    // Add more metadata as needed
  }

  ngOnInit(): void {
  }

}
