import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import baseUrl from './helper';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private _http: HttpClient) { }

  //create post
  public createPost(post){
    return this._http.post(`${baseUrl}/post/v1/createpost`,post);
  }

  //get post
  public getPostByTitle(title){
    return this._http.get(`${baseUrl}/post/v1/publishedpost/${title}`,);
  }

  //get post
  public getAllPost(pageNo, pageSize, sortBy,sortDir){
    return this._http.get(`${baseUrl}/post/v1/posts?pageSize=${pageSize}&pageNo=${pageNo}&sortBy=${sortBy}&sortDir=${sortDir}`,);
  }


}
