import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddCategoryComponent } from './pages/admin/add-category/add-category.component';
import { AddQuestionsComponent } from './pages/admin/add-questions/add-questions.component';
import { AddQuizComponent } from './pages/admin/add-quiz/add-quiz.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component';
import { UpdateCategoryComponent } from './pages/admin/update-category/update-category.component';
import { UpdateQuestionComponent } from './pages/admin/update-question/update-question.component';
import { UpdateQuizComponent } from './pages/admin/update-quiz/update-quiz.component';
import { ViewCategoriesComponent } from './pages/admin/view-categories/view-categories.component';
import { ViewQuizQuestionsComponent } from './pages/admin/view-quiz-questions/view-quiz-questions.component';
import { ViewQuizzesComponent } from './pages/admin/view-quizzes/view-quizzes.component';
import { WelcomeComponent } from './pages/admin/welcome/welcome.component';
import { Error404Component } from './pages/errorpage/error404/error404.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AboutQuizComponent } from './pages/user/about-quiz/about-quiz.component';
import { InstructionsComponent } from './pages/user/instructions/instructions.component';
import { LoadQuizComponent } from './pages/user/load-quiz/load-quiz.component';
import { StartComponent } from './pages/user/start/start.component';
import { UserDashboardComponent } from './pages/user/user-dashboard/user-dashboard.component';
import { UserGuard } from './services/user.guard';
import { ReportGenerateComponent } from './pages/user/report-generate/report-generate.component';
import { OrderComponent } from './pages/user/payment/order/order.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ActivateAccountComponent } from './pages/signup/activate-account/activate-account.component';
import { CompetitionComponent } from './pages/user/competition/competition.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './pages/reset-password-confirmation/reset-password-confirmation.component';
import { QuestionOfTheDayComponent } from './pages/question-of-the-day/question-of-the-day.component';
import { CreatePostComponent } from './pages/post/create-post/create-post.component';
import { PublishedPostComponent } from './pages/post/published-post/published-post.component';
import { PostListComponent } from './pages/post/post-list/post-list.component';
import { ContactusComponent } from './pages/contactus/contactus.component';

const routes: Routes = [

  // For Home Page
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },

  // For Signup Page
  {
    path: 'signup',
    component: SignupComponent,
    pathMatch: 'full',
  },

  // for Account-activate page
  {
    path: 'account-activate',
    component: ActivateAccountComponent,
    pathMatch: 'full',
    canActivate: [UserGuard],
  },

  // For Login Page
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },

  // For Aboutus Page
  {
    path: 'aboutus',
    component: AboutusComponent,
    pathMatch: 'full',
  },
  {
    
    path: 'dash',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'posts',
    children: [
      {
        path: 'createpost',
        component: CreatePostComponent,
        pathMatch: 'full',
        canActivate: [UserGuard],
      },
      {
        path: 'publishedpost/:title',
        component: PublishedPostComponent,
        pathMatch: 'full',

      },
      {
        path: '',
        component: PostListComponent,
        pathMatch: 'full'
      }
    ]
  },
  // For Normal User Dashboard
  {
    path: 'user-dashboard',
    component: UserDashboardComponent,
    // Normal User Gaurd
    canActivate: [UserGuard],
    children:[
      {
        path: ':catId',
        component:LoadQuizComponent,
      },

      {
        path: 'instructions/:qid',
        component: InstructionsComponent,
      },

      // about quiz view page
      {
        path: 'about-quiz/:qid',
        component: AboutQuizComponent,
      },

    ],
  },
  {
    path: 'start/:qid',
    component: StartComponent,
    canActivate: [UserGuard],
  },
  {
    path: 'start/competition/:qid',
    component: CompetitionComponent,
    canActivate: [UserGuard],
  },

  {
    path: 'profile',
    component: ProfileComponent,
    pathMatch: 'full',
    canActivate: [UserGuard],
  },

  {
    path: 'generate-report/:examId',
    component: ReportGenerateComponent,
    canActivate: [UserGuard],
  },
  { 
    path: 'order/:subjectid',
    component: OrderComponent,
    pathMatch: 'full',
    canActivate: [UserGuard],
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'resetConfirm',
    component: ResetPasswordConfirmationComponent,
    pathMatch: 'full'
  }, 
  {
    path: 'qoftheday',
    component: QuestionOfTheDayComponent,
    pathMatch: 'full'
  },
  {
    path: 'contactus',
    component: ContactusComponent,
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
