<h1 class="mt20">Questions of {{qTitle}}</h1>

<div class="bootstrap-wrapper">
    <div class="row">
        <div class="col-md-12">
            <div class="container text-center">
                <button [routerLink]="'/admin/add-questions/' +qId+'/'+qTitle" mat-raised-button color="primary">Add Questions</button>
            </div>
            <mat-card *ngFor="let q of questions ; let i = index" class="mt20">
                <mat-card-content>
                    <p>
                        <b>Q {{i+1}} :</b>
                        <span class="ml20" [innerHTML]="q.content"></span>
                    </p>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6">
                                <p><b>(A) </b>{{q.option1}}</p>
                            </div>

                            <div class="col-md-6">
                                <p><b>(B) </b>{{q.option2}}</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <p><b>(C) </b>{{q.option3}}</p>
                            </div>

                            <div class="col-md-6">
                                <p><b>(D) </b>{{q.option4}}</p>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <p class="mt20">
                            <b>Correct Answer:</b> {{q.answer}}
                        </p>
                    </div>
                </mat-card-content>

                <mat-card-action>
                    <button [routerLink]="'/admin/question/'+q.quesId" mat-raised-button color="accent" class="ml20">Update</button>
                    <button (click)="deleteQuestionById(q.quesId)" mat-raised-button color="warn" class="ml20">Delete</button>
                </mat-card-action>

            </mat-card>
        </div>
    </div>
</div>