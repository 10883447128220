<div class="bootstarp-wrapper">
    <div class="container-fluid">
        <h1 class="mt20">Available Category</h1>
        <div class="row" mt20 *ngIf="categories">
            <div class="col-md-6" *ngFor="let c of categories">
                <mat-card class="mb20">
                    <mat-card-header>
                        <div mat-card-avatar class="example-header-image"> </div>
                        <mat-card-title>
                            {{c.title}}
                        </mat-card-title>
                        <mat-card-subtitle>
                            {{c.title}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p class="truncate">{{c.description}}</p>
                    </mat-card-content>

                    <mat-card-actions>
                        <!-- <button mat-button color="primary" [routerLink]="'/user-dashboard/about-quiz/'+q.qid"> View</button> -->
                        <button mat-raised-button color="primary" class="ml20" [routerLink]="'/admin/category/'+c.cid">Update</button>
                        <button mat-raised-button color="accent" class="ml20" (click)="deleteCategory(c.cid)">Delete</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="col-md-12" *ngIf="categories.length==0">
                <mat-card>
                    <mat-card-content class="text-center">
                        <h1>No Category Available</h1>
                    </mat-card-content>

                    <mat-card-actions class="text-center">
                        <button mat-raised-button color="primary" [routerLink]="'/admin/add-category'"> Add New Category</button>
                    </mat-card-actions>

                </mat-card>
            </div>
        </div>
    </div>
</div>