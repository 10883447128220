<h1 class="mt20">Available Quiz</h1>
<mat-card class="mt10 mr20 ml20" *ngFor="let q of quizdata">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>
            {{q.title}}
        </mat-card-title>
        <mat-card-subtitle>{{q.category.title}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <p class="truncate">{{q.description}}</p>

    </mat-card-content>

    <mat-card-action>

        <button mat-stroked-button color="accent" class="ml20">Max Marks: {{q.maxMarks}}</button>
        <button mat-stroked-button color="accent" class="ml20">Questions: {{q.numberOfQuestions}}</button>
        <button mat-stroked-button color="accent" class="ml20">Quiz Publish : {{q.active}}</button>
        <button [routerLink]="'/admin/view-questions/'+q.qid+'/'+q.title" mat-button color="primary">Questions</button>
        <button mat-button color="accent" class="ml20" color="primary">Attempts</button>
        <button [routerLink]="'/admin/quiz/' + q.qid" mat-button color="primary" class="ml20">Update</button>
        <button mat-button color="warn" class="ml20" (click)="deleteQuiz(q.qid)">DELETE</button>

    </mat-card-action>
</mat-card>


<div class="col-md-12" *ngIf="quizdata.length==0">
    <mat-card class="mt20">
        <mat-card-content class="text-center">
            <h1>No Quiz Available</h1>
        </mat-card-content>

        <mat-card-actions class="text-center">
            <button routerLink='/admin/add-quiz' mat-raised-button color="accent">Add New Quiz</button>
        </mat-card-actions>

    </mat-card>
</div>


<!-- <div class="container text-center mt20">
    <button routerLink='/admin/add-quiz' mat-raised-button color="accent">Add New Quiz</button>
</div> -->