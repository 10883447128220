import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  subjectLsit : string;
  constructor(private homeService: HomeService) { }


  ngOnInit(): void {
    this.getAllExamCards();
  }

  getAllExamCards(){
    this.homeService.getAllSubjectsCard().subscribe(
      (data: any) => {
        console.log("data is" + JSON.stringify(data));
        this.subjectLsit = data.data;
      },
      (error)=> {
        
      }
    );
  }


}
