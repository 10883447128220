import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { error } from 'console';
import { QuestionService } from 'src/app/services/question.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  //Local variable
  qid;
  questions;
  cq;// Current Question
  cqNumber = 0;
  buttonList : any;
  exam = {};
  questinAnswerList = []

  // Calculating marks
  marksGot = 0;
  correctAnswers = 0;
  attempted  = 0;
  isSubmit = false;
  isTimer: any;
  subject;
  grade;

  constructor(private _locationSt : LocationStrategy,private route: Router, private _route : ActivatedRoute,private _questionService: QuestionService) { }

  ngOnInit(): void {

    this.qid = this._route.snapshot.params.qid;
    if(localStorage.getItem("isPaymentSuccessfull") == "true"){

      this.subject = JSON.parse(localStorage.getItem("subject")).name;
      this.grade = JSON.parse(localStorage.getItem("subject")).gradeId;

      this.loadQuestions();
      this.preventBackButton();

    } else {
      this.route.navigate(['/']);
    }
    
  }

  loadQuestions() {
    
    this._questionService.getQuestionsOfQuizBySubjectId(this.qid).subscribe(
      (data:any)=>{
        //success
        this.questions = data.data;
        console.log("total questions", this.questions);
        this.cq = this.questions[0];
        this.cqNumber = 0;
        // Timmer will start while questions is loading
        this.isTimer = this.questions.length * 2 * 60;

        // // add new key and value for user given answers
        this.questions.forEach((q)=>{
          this.questinAnswerList.push({
            "qid": q.questionId,
            "question": q.question,
            "givenAnswer" : "N.A"
          });
        });

        console.log("this.questinAnswerList  ", this.questinAnswerList);

        //Start timer function here
        this.startTimmer();
      },
      (error)=>{
        //error
        Swal.fire("Error","Server error while loading questions","error");
      },
    );
  }
   //Prevent to back button
   preventBackButton(){
      
    history.pushState(null,null,location.href);
    this._locationSt.onPopState(()=>{
      history.pushState(null,null,location.href);
    });

  }

  startTimmer(){
    let t = window.setInterval(()=>{
      //code
      if(this.isTimer <=0){

        // After Completing time quiz will submitedd automatically without confirmation
        this.submitAnswer();
        clearInterval(t);
      }else{
        this.isTimer--;
      }
    },1000);
  }

  //time formated
  getFormatedTime(){
    let min = Math.floor(this.isTimer / 60);
    let sec = this.isTimer - min * 60;
    return `${min} Min : ${sec} Sec`;
    
  }

  // Print Page
  printPage(){
    window.print();
  }
  
  prev(){
    if(this.cqNumber > 0){
      this.cqNumber--;
      this.cq = this.questions[this.cqNumber];
    } 
  }

  next(){
    if(this.checkSelection()){
      this.setExamObject();
      this._questionService.saveEachQuestionAndAnswer(this.exam);
      this.getAllButtons();
      this.buttonList[this.cqNumber].style['background-color'] = '#7ab27a';
      var isAnswered = this.buttonList[this.cqNumber].getAttribute('ng-attr-answered');
      if(this.cqNumber <= this.questions.length - 2){
        this.cqNumber++;
        this.cq = this.questions[this.cqNumber];
      }
    } else{
      Swal.fire({
        title: 'Please select atleast one option',
        showCancelButton: false,
        confirmButtonText:'OK',
        icon: 'warning',
      })
    }
  }

  getSelectedQuestion(val: number){
    this.cqNumber = val;
    this.cq = this.questions[this.cqNumber];
    this.getAllButtons();
    if(!this.cq.givenAnswer)
      this.buttonList[this.cqNumber].style['background-color'] = 'Orange';
  }

  getAllButtons(){
    if(this.buttonList == undefined){
      console.log("Initial");
      var buttonContainer = document.querySelector('#button-container');
      var buttons = buttonContainer.querySelectorAll('button');
      this.buttonList = buttons;
    }
  }

  checkSelection() {
    if (this.cq.givenAnswer === undefined) {
      return false
    } else {
      return true;
    }
  }

  setExamObject(){
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let userId = userDetails.id;
    // this.questinAnswerList.push({
    //   "qid": this.cq.questionId,
    //   "question": this.cq.question,
    //   "givenAnswer" : this.cq.givenAnswer
    // });
    var questionId = this.cq.questionId;

    var itemToUpdate = this.questinAnswerList.filter(function(item) {
      return item.qid === questionId;
    })

    // Update the "givenAnswer" property for each filtered item
    itemToUpdate.forEach(function(item) {
      item.givenAnswer = this.cq.givenAnswer;
    }, this);
    
    this.exam = {
      "studentId" : userId,
      "subjectId":  this.qid,
      "studentAnswer": JSON.stringify(this.questinAnswerList)
    };
  }

  // Submit Quiz and calculating
  submitAnswer(){
    Swal.fire({
      title: 'Do you want submit quiz',
      showCancelButton: true,
      confirmButtonText:'Submit Quiz',
      icon: 'warning',
    }).then((e)=>{
      if(e.isConfirmed){
        // User manually submit quiz then ask confirmation before submited quiz
        this._questionService.submitQuestionAndAnswer(this.exam).subscribe(
          (response: any)=>{
            console.log("Response ==>", response);
            localStorage.removeItem("exam");
            localStorage.removeItem("isPaymentSuccessfull");
            this.route.navigate(['generate-report/'+response.data]);
          },
          (error) => {
            alert();
            console.log("Error ==> ", error);
          }
        )
      }
    })
  }

}