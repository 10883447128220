import { Component, OnInit } from '@angular/core';
import { QuizService } from 'src/app/services/quiz.service';
import Swal from 'sweetalert2'
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-question-of-the-day',
  templateUrl: './question-of-the-day.component.html',
  styleUrls: ['./question-of-the-day.component.css']
})
export class QuestionOfTheDayComponent implements OnInit {

  options = [];
  question;
  givenAnswer;
  email;
  id;

  constructor(private metaService: MetaService, private route: Router, private quizService: QuizService, private snack: MatSnackBar) { }

  ngOnInit(): void {

    // Set the Meta tags
    this.metaService.setMetaData({
      title: 'ExamDedo: Question Of The Day',
      description: 'Give your answer to the question of the day.',
      thumbnail: 'https://examdedo.com/assets/qday.png'
    });

    this.quizService.getQOftheDay().subscribe((resp)=> {
      var data = resp["data"];
      this.options = data.options.split(",");
      this.question = data.question;
      this.id = data.id;
      console.log(data)

    },
    (error) => {

    });
  }

  formSubmit() {
    if(this.givenAnswer == undefined || this.givenAnswer == null){
      this.snack.open("Select option!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }
    if(this.email == undefined || this.email == null){
      this.snack.open("Enter your email!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(this.email)) {
      this.snack.open("Please Enter valid email!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }
    this.saveResponse();
  }

  saveResponse() {
    var payload = {
      "id": this.id,
      "question": this.question,
      "answer": this.givenAnswer,
      "email": this.email
    };
    this.quizService.saveRespOfQOftheDay(payload).subscribe((resp)=> {
      var data = resp["data"];
      if(data == true) {
        Swal.fire("Success" , "Congrats, Your answer is correct! Redirecting to homepage" , "success").then(resp => {
          if(resp.isConfirmed) {
            this.route.navigate(['/']);
          }
        });
      } else {
        Swal.fire("Oops! Your answer is not correct", '', 'error');
      }
    },
    (error) => {
      Swal.fire(error.error.errors[0], '', 'error').then(err => {
        this.route.navigate(['/']);
      });
    });
  }
}
