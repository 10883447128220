<div class="records-container">
    <div class="record">
        <p class="record-title text-center">Latest Government Jobs</p>
    </div>
    <div class="record">
      <p class="record-title"><a href="https://pb.icf.gov.in/act/index.php" target="_blank">Railway Integral Coach Factory</a></p>
      <p class="record-description">Integral Coach Factory (ICF, Chennai) has relased Various Trade Apprentice 2024. Those candidates who are interested in this Railway ICF Apprentices recruitment can apply online from 22 May 2024 to 21 June 2024</p>
    </div>
    <div class="record">
      <p class="record-title"><a href="https://apprentice.rrcner.net/" target="_blank">	
        North Eastern Railway NER Gorakhpur Various Trade Apprentices</a></p>
      <p class="record-description">Bihar Public Service Commission (BPSC) BHO Recruitment 2024. Those candidates who are interested in this BPSC Block Horticulture Officer in Horticulture Directorate under Agriculture Department, Govt. of Bihar. recruitment can apply online Re Open from 23 May 2024 to 29 May 2024</p>
    </div>
    <div class="record">
      <p class="record-title"><a href="https://rectt.bsf.gov.in/" target="_blank">Vacancy in BSF</a></p>
      <p class="record-description">Group-B (Non Gazetted-Non Ministerial) (Combatised) post in the Border Security Force, Ministry of Home Affairs 2024</p>
    </div>
    <div class="record">
      <p class="record-title"><a href="https://agnipathvayu.cdac.in/AV/img/rally/AV_Musician_Rally_01_2025.pdf" target="_blank">Indian Airforce Agniveer</a></p>
      <p class="record-description">INVITES UNMARRIED INDIAN MALE AND FEMALE CANDIDATES TO APPEAR IN RECRUITMENT RALLY FROM 03 JULY 2024 TO 12 JULY 2024 AT 3 ASC
        C/O AIR FORCE STATION KANPUR (UTTAR PRADESH) AND 7 ASC, NO. 1 CUBBON ROAD, BENGALURU (KARNATAKA) TO JOIN THE IAF AS AGNIVEERVAYU (MUSICIAN)</p>
    </div>
    <div class="record">
        <p class="record-title"><a href="https://upsifsrec.samarth.edu.in/" target="_blank">UPSIFS Teaching/Non Teaching Various Post</a></p>
        <p class="record-description">UPSIFS has relased Under The Recruitment Drive for Teaching and Non Teaching Various Post 2024</p>
    </div>
    <div class="record">
        <p class="record-title"><a href="https://upsconline.nic.in/upsc/OTRP/index.php" target="_blank">UPSC Combined Defence Service</a></p>
        <p class="record-description">UPSC Combined Defence Service Exam II Second Recruitment 2024. Those candidates who are interested in this UPSC CDS Second 2024 Examination can apply online from 15 May 2024 to 04 June 2024</p>
    </div>

    <!-- Add more records as needed -->
  </div>