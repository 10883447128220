import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { UserService } from 'src/app/services/user.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  phone: string = '';
  resetPasswordObj = {};
  constructor(private userService: UserService, private route: Router){ }


  ngOnInit(): void {
  }

  resetPassword() {
    if(!this.isOnlyDigit(this.phone) || this.phone.trim().length != 10) {
      Swal.fire('Enter valid phone number', '', 'error');
    } else{
      this.resetPasswordObj["phone"] = this.phone
      this.userService.resetPassword(this.resetPasswordObj).subscribe((resp: HttpHeaderResponse) =>{
        localStorage.setItem("phone", this.phone);
        Swal.fire("Success" , "OTP sent on your mail to reset password!" , "success");
        this.route.navigate(['/resetConfirm']);
      },(error) => {
        console.log(error);
        Swal.fire(error.error.errors[0], '', 'error');
      }
      )
    }
  }

  isOnlyDigit(text){
    const digitsOnlyRegex: RegExp = /^\d+$/;
    return digitsOnlyRegex.test(text);
  }

}
