import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { LoginService } from 'src/app/services/login.service';
import { QuestionService } from 'src/app/services/question.service';

declare var Razorpay: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  form: any = {}; 
  paymentId: string;
  error: string;
  user = null;
  subjectid;
  isPaymentSuccessfull = false;
  fee;
   
  constructor(private questionService: QuestionService, private loginService: LoginService, private orderService: OrderService, private _route: ActivatedRoute, private _router: Router) {

  }

  options = {
    "key": "",
    "amount": "", 
    "name": "ExamDedo Portal",
    "description": "Online Exam Portal",
    "image": "",//"https://www.javachinna.com/wp-content/uploads/2020/02/android-chrome-512x512-1.png",
    "order_id":"",
    "handler": function (response){
        var event = new CustomEvent("payment.success", 
            {
                detail: response,
                bubbles: true,
                cancelable: true
            }
        );    
        window.dispatchEvent(event);
    }
    ,
    "prefill": {
    "name": "",
    "email": "",
    "contact": ""
    },
    "notes": {
    "address": ""
    },
    "theme": {
    "color": "#3399cc"
    }
    };

  ngOnInit(): void {
    this.subjectid = this._route.snapshot.params.subjectid;
    this.user = this.loginService.getUser();
    var subject = localStorage.getItem("subject");
    this.fee = JSON.parse(subject).price;
    //localStorage.removeItem("subject");
  }

  
  onSubmit(): void {
    this.paymentId = ''; 
    this.error = ''; 
    this.form.name = this.user.name;
    this.form.email = this.user.email;
    this.form.amount = this.fee;
    this.form.phone = this.user.phoneno;

    this.orderService.createOrder(this.form).subscribe(
    resp => {
        var data = resp.data;
        this.options.key = data.secretKey;
        this.options.order_id = data.razorpayOrderId;
        this.options.amount = data.applicationFee; //paise
        this.options.prefill.name = this.form.name;
        this.options.prefill.email = this.form.email;
        this.options.prefill.contact = this.form.phone;
        var rzp1 = new Razorpay(this.options);
        rzp1.open();
                   
        rzp1.on('payment.failed', function (response){    
            // Todo - store this information in the server
            console.log(response.error.code);    
            console.log(response.error.description);    
            console.log(response.error.source);    
            console.log(response.error.step);    
            console.log(response.error.reason);    
            console.log(response.error.metadata.order_id);    
            console.log(response.error.metadata.payment_id);
            this.error = response.error.reason;
        }
        );
    }
    ,
    err => {
        this.error = err.error.message;
    }
    );
  }

  @HostListener('window:payment.success', ['$event']) 
    onPaymentSuccess(event): void {
        this.orderService.updateOrder(event.detail).subscribe(
        data => {
            this.paymentId = data.message;
            // if (typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1) {
            //     redirect_url = '/you-owe-money.html';
            //   } else {
            //     redirect_url = '/thnx-you-paid.html';
            //   }
              //location.href = "/";
            this.isPaymentSuccessfull = true;
            localStorage.setItem("isPaymentSuccessfull", "true");
            let subject = this.questionService.getSubject();
            if(subject.competitive) {
              this._router.navigate(['/start/competition/' + this.subjectid]);
            } else {
              this._router.navigate(['/start/' + this.subjectid]);
            }
            
        }
        ,
        err => {
            this.error = err.error.message;
        }
        );
    }
}
