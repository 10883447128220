import { Component, OnInit } from '@angular/core';
import { ThirdPartyServiceService } from 'src/app/services/third-party-service.service';

@Component({
  selector: 'app-on-this-day',
  templateUrl: './on-this-day.component.html',
  styleUrls: ['./on-this-day.component.css']
})
export class OnThisDayComponent implements OnInit {
  formattedDate: string;
  events;

  constructor(private thirdPartyService: ThirdPartyServiceService) { 
    this.formatDate();
  }

  ngOnInit(): void {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    this.thirdPartyService.fetchData(day, month+1).subscribe((resp)=>{
      this.events = resp["data"]["events"];
      if(this.events == undefined || this.events == null){
        this.events = this.generateStaticData();
      }
    },
    (error)=>{
      this.events = this.generateStaticData();
    })
  }

  generateStaticData(){
    var data =[{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    },{
      "year":"First Prime Minister",
      "description": "Pt. Jawaharlal Nehru"
    }]

    return data;
  }

  formatDate() {
    const today = new Date();
    const day = today.getDate();
    const month = this.getMonthName(today.getMonth());
    const ordinalSuffix = this.getOrdinalSuffix(day);
    this.formattedDate = `${day}${ordinalSuffix} ${month}`;
  }

  getMonthName(month: number): string {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[month];
  }

  getOrdinalSuffix(n: number): string {
    if (n >= 11 && n <= 13) {
      return 'th';
    }
    switch (n % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }  

}
