<div class="bootstrap-wrapper">

    <div class="container">

        <div class="row">
            <div class="col-md-8 offset-md-2">
                <mat-card>
                    <form (ngSubmit)="resetPasswordConfirmation()">
                        <h1 class="text-center">Reset Password</h1>
                        <h5 class="text-center">Check your mail for the OTP</h5>
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>OTP</mat-label>
                                    <input required name="otp" [(ngModel)]="otp" type="text" minlength="5"  maxlength="5" matInput placeholder="Enter OTP">
                                 </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input required name="password" [(ngModel)]="password" type="password" matInput placeholder="Enter password">
                                 </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Confirm Password</mat-label>
                                    <input required name="confirmPassword" [(ngModel)]="confirmPassword" type="password" matInput placeholder="Confirm password">
                                 </mat-form-field>
                            </div>
                            <div class="container text-center">
                                <button  mat-raised-button color="primary" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>