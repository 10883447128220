import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    constructor(private snackBar: MatSnackBar , private loginService: LoginService, private router: Router) { }

    // Login Object
  loginData = {
    username: '',
    password: '',
  };

  ngOnInit(): void {
  }

  forgotPass() {
    this.router.navigate(['/reset']);
  }
  // Login form Submit method
  loginFormSubmit(){
    console.log('Login Button Clicked');
    console.log(this.loginData);

    // Validations of login form
    // For Username
    // tslint:disable-next-line:triple-equals
    if (this.loginData.username.trim() == '' || this.loginData.username == null){
      // alert("Username required");
      // MatSnackBar
      this.snackBar.open('Username is required!!!', 'OK', {
        duration: 3000,
      });
      return;
    }

    // For Password
    // tslint:disable-next-line:triple-equals
    if (this.loginData.password.trim() == '' || this.loginData.password == null){
      this.snackBar.open('Password is required!!!', 'ok', {
        duration: 3000,
      });
      return;
    }

    // Request to server to generate JWT Token
    this.loginService.generateToken(this.loginData).subscribe(
        (data: any) => {
          // Success
          console.log(data);

          // After Successfully generated token then login from here!!!
          this.loginService.loginUser(data.token);

          // getting current user
          this.loginService.getCurrentUser(this.loginData.username).subscribe(
            (response: any) => {

              // save user data into localStorage
              this.loginService.setUser(response.data);
              console.log(response);

              this.router.navigate(['/']);
              this.loginService.loginStatusSubject.next(true);
            },
          );

        },
        (error) => {
          // Error Occured
          console.log('Oops...', 'Seems like Something went wrong!!!', error);
          this.snackBar.open('Invaild credentials!!! Try Agin', 'ok', {
            duration: 3000,
          });
        }
      );


  }

}
