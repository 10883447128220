<div class="bootstrap-wrapper">

    <div class="container-fluid">

        <div class="row">

            <div class="col-md-2">
                <app-sidebar></app-sidebar>
            </div>

            <div class="col-md-10">
                <!-- Use for content view same page -->
                <router-outlet></router-outlet>
            </div>

        </div>

    </div>

</div>