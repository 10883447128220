<body>

    <div class="bootstrap-wrapper">

        <div class="container">

            <div class="row">

                <div class="col md-10">
                    <div class="stage">
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>
                        <div class="layer"></div>

                    </div>



                </div>

            </div>

        </div>

    </div>

</body>