import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import baseUrl from './helper';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor(private _http: HttpClient) { }

  public getQuestionsOfQuiz(qid) {
    return this._http.get(`${baseUrl}/question/quiz/all/${qid}`);
  }

  public getQuestionsOfQuizBySubjectId(qid) {
    return this._http.get(`${baseUrl}/quiz/v1/getQuestions/${qid}`);
  }

  public getCompetitionQuestionsOfQuizBySubjectId(qid) {
    return this._http.get(`${baseUrl}/quiz/v1/getCompetitionQuestions/${qid}`);
  }


  //add questions
  public addQuestion(questions){
    return this._http.post(`${baseUrl}/question/`,questions);
  }

  //getting particular question from the sever by using Id
  public particularQuestionById(quesId){
    return this._http.get(`${baseUrl}/question/${quesId}`);
  }

  //question update
  public updateQuestionById(questions){
    return this._http.post(`${baseUrl}/question/`,questions);
  }

  //delete particular question by Id
  public deletingParticularQuestion(quesId){
    return this._http.delete(`${baseUrl}/question/${quesId}`);
  }

  //to validate questions answers call to server and check questions answer on sever
  public validateQuestionsAndAnswer(questions){
    return this._http.post(`${baseUrl}/question/eval-quiz`,questions);
  }

  //to validate questions answers call to server and check questions answer on sever
  public submitQuestionAndAnswer(exam){
    return this._http.post(`${baseUrl}/exam/v1/saveAnswer`,exam);
  }

   //get SubjectDetails
   public getSubject(){
    let subjectStr = localStorage.getItem("subject");
    if (subjectStr != null){
      return JSON.parse(subjectStr);
    }else{
      return null;
    }
  }

  // save individual questions in local storage
  public saveEachQuestionAndAnswer(exam) {
    localStorage.setItem("exam", JSON.stringify(exam));
    return true;
  }
}
