<div class="bootstrap-wrapper">

    <div class="container">

        <div class="row">

            <div class="col-md-6 offset-md-3">

                <mat-card>
                    <div class="container text-center">

                        <img src="../../../assets/register1.png" width="80px" height="80px" alt="">

                    </div>

                    <h1 class="text-center">Register Here!!</h1>

                    <!-- Show data above the form -->
                    <!-- {{user | json }} -->

                    <form (ngSubmit)="formSubmit()">
                        <div class="row">
                            <div class="col-md-6">
                               
                                <!--Name field-->
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Name</mat-label>
                                    <input required [(ngModel)]="user.name" name="name" pattern="[a-zA-Z ]+" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                
                                <!--Phone field-->
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Phone Number</mat-label>
                                    <input required [(ngModel)]="user.phoneno" name="phoneno" type="number" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <!--Email field-->
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Email Address</mat-label>
                                    <input required [(ngModel)]="user.email" name="email" type="email" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <!--Referral code field-->
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Optional Referral Code</mat-label>
                                    <input [(ngModel)]="user.referralCode" name="referralCode" type="text" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                        </div>
                        

                        <div class="row">
                            <div class="col-md-6">
                               
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Grade:</mat-label>
                                    <mat-select name="grade" [(ngModel)]="user.grade">
                                      <mat-option value="" disabled>Select Grade</mat-option>
                                      <mat-option value="9">9th - Pre highschool</mat-option>
                                      <mat-option value="10">10th - HighSchool</mat-option>
                                      <mat-option value="11">11th - Pre Intermediate</mat-option>
                                      <mat-option value="12">12th - Intermediate</mat-option>
                                      <mat-option value="13">B.Sc.</mat-option>
                                      <mat-option value="14">B.Tech/B.E</mat-option>
                                      <mat-option value="15">B.Pharm</mat-option>
                                      <mat-option value="16">BA</mat-option>
                                      <mat-option value="17">Other</mat-option>
                                      <mat-option value="18">Not a student</mat-option>
                                      <!-- Add more grade options as needed -->
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Preparing for</mat-label>
                                    <mat-select name="preparingFor" [(ngModel)]="user.preparingFor">
                                        <mat-option value="" disabled>Preparing For</mat-option>
                                        <mat-option value="iit">IIT-JEE</mat-option>
                                        <mat-option value="cat">CAT/MAT</mat-option>
                                        <mat-option value="neet">NEET</mat-option>
                                        <mat-option value="bank">Banking</mat-option>
                                        <mat-option value="ssc">SSC</mat-option>
                                        <mat-option value="gate">GATE</mat-option>
                                        <mat-option value="gpat">GPAT</mat-option>
                                        <mat-option value="ugc">UGC-NET</mat-option>
                                        <mat-option value="csir">CSIR-NET</mat-option>
                                        <mat-option value="other">Other</mat-option>
                                        <!-- Add more grade options as needed -->
                                      </mat-select>
                                    </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input required [(ngModel)]="user.password" name="password" type="password" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Confirm Password</mat-label>
                                    <input required [(ngModel)]="user.confirmPassword" name="confirmPassword" type="password" matInput placeholder="Enter Here!">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="container text-center">

                            <button type="submit" mat-raised-button color="primary">Register</button>
                            <button type="reset" class="ml20" mat-raised-button color="accent">Clear</button>
                        </div>
                    </form>
                </mat-card>
            </div>
        </div>
    </div>
</div>