<div class="bootstarp-wrapper">
    <div class="container-fluid">
        <h1 class="mt20">Available Quizzes</h1>
        <div class="row" mt20 *ngIf="quizzList">
            <div class="col-md-4" *ngFor="let q of quizzList">
                <mat-card class="mb20">
                    <mat-card-header>
                        <div mat-card-avatar class="example-header-image"> </div>
                        <mat-card-title>
                            {{q.title}}
                        </mat-card-title>
                        <mat-card-subtitle>
                            {{q.category.title}}
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p class="truncate">{{q.description}}</p>
                    </mat-card-content>

                    <mat-card-actions>
                        <button mat-button color="primary" [routerLink]="'/user-dashboard/about-quiz/'+q.qid"> View</button>
                        <button mat-button color="accent" class="ml20" [routerLink]="'/user-dashboard/instructions/'+q.qid"> Start</button>
                        <button mat-button color="primary"> Q:{{q.numberOfQuestions}}</button>
                        <button mat-button color="accent"> M M:{{q.maxMarks}}</button>
                    </mat-card-actions>
                </mat-card>
            </div>
            <div class="col-md-12" *ngIf="quizzList.length==0">
                <mat-card>
                    <mat-card-content class="text-center">
                        <h1>No data Available in this Category</h1>
                    </mat-card-content>

                    <mat-card-actions class="text-center">
                        <button mat-raised-button color="primary" [routerLink]="'/user-dashboard/0'"> Home</button>
                    </mat-card-actions>

                </mat-card>
            </div>
        </div>
    </div>
</div>