import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from 'src/app/services/login.service';
import { PostService } from 'src/app/services/post.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.css']
})
export class CreatePostComponent implements OnInit {

  public Editor = ClassicEditor;
  public blog = {
    title: '',
    content: '',
    author: {
      name: '',
      email: '',
      authorId: ''
    }
  };

  constructor(private router: Router, private postService: PostService, private snack: MatSnackBar, private loginService: LoginService) { }

  ngOnInit(): void {
    var user = this.loginService.getUser();
    this.blog.author.name = user.name;
    this.blog.author.email = user.email;
    this.blog.author.authorId = user.id;
  }

  postBlog(){
    if(this.blog.title == undefined || this.blog.title == null || this.blog.title.trim() == ''){
      this.snack.open("Enter Proper Title!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }
    if(this.blog.content == undefined || this.blog.content == null || this.blog.content.trim() == ''){
      this.snack.open("Enter Proper content!!", 'ok', {
        duration: 3000,
        // verticalPosition:'top'
      });
      return;
    }

    this.postService.createPost(this.blog).subscribe(
      (resp)=>{
        Swal.fire({
          title: 'Your blog has been posted!',
          showCancelButton: false,
          confirmButtonText:'OK',
          icon: 'success',
        }).then(e => {
          if(e.isConfirmed) {
            this.router.navigate(['/posts']);
          }
        })
      },
      (error)=>{
        alert("errro")
      })

    
    console.log(this.blog);
  }

}
