<div class="bootstrap-wrapper">
    <div class="banner-container">
      <img src="./assets/jj.png" alt="Banner Image" class="banner-image">
  </div>

        <div class="row mt20">
            <div class="col-md-9">
                <div class="row">
                    <div *ngIf="subjectLsit"></div>
                    <div class="col-md-12 margin25">
                        <div class="records-container">
                            <div class="record">
                                <p class="record-title text-center">Below are the list of mock test. Evaluate your performance today</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" *ngFor="let q of subjectLsit">
                        <mat-card class="mb20 custom-card">
                            <!-- <img mat-card-image src="./../assets/quiz1.jpg"/> -->
                            <mat-card-content>
                                <div class="card-header capitalize">
                                <h2>{{q.name}}</h2>
                                <p>Price: <span>&#8377;</span>{{q.price}}</p>
                                </div>
                                <div class="card-details">
                                    <h3 style="color: blue;"> 
                                        <span *ngIf="q.gradeId >= 100">{{q.name}}</span>
                                        <span *ngIf="q.gradeId < 100">Standard: {{q.gradeId}}th Class</span>
                                    </h3>
                                    <p>Duration: {{q.duration}} Minutes</p>
                                    <p>Questions: {{q.numberOfQuestions}}</p>
                                
                                </div>
                            </mat-card-content>

                            <mat-card-actions style="text-align: center;">
                                <!-- <button mat-button color="primary" [routerLink]="'/user-dashboard/about-quiz/'+q.qid"> View</button> -->
                                <button style="font-size: 18px;" mat-button color="accent" attr.data-subjectName="{{q.name}}" [routerLink]="'/user-dashboard/instructions/'+q.id"> Start Test</button>
                                <!-- <button mat-button color="primary"> Questions: 50{{q.numberOfQuestions}}</button> -->
                            </mat-card-actions>

                        </mat-card>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <app-on-this-day></app-on-this-day>
            </div>
            <!-- <div class="col-md-3">
                <app-govtjobs></app-govtjobs>
            </div> -->
        </div>
    
</div>